import React, { useContext } from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { Container, Section, SectionPageTitle } from "../components/Section";
import { Context } from "../components/context";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import { ListBadge } from "../components/Sidebar";
import Badge from "../components/Badge";
import { Helmet } from "react-helmet";
import PrimaryButton from "../components/Button/PrimaryButton";

const ComparisonTableData = styled.div``

const ComparisonTable = styled.table`
  border-collapse: collapse;
  th:first-child, table td:first-child {
    width: 200px;
  }

  th, td {
    border: 1px solid #ccc;
    padding: 3px 6px;
  }

  tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  tr:nth-child(odd) {
    background-color: #fff;
  }
`

const ImageCell = styled.td`
  text-align: center;
  max-height: 120px;

  img {
    padding: 4px;
    line-height: 1.62857143;
    background-color: #ffffff;
    border: 1px solid #dddddd;
    border-radius: 0;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    display: inline-block;
    max-width: 100%;
    height: auto;
  }
`

const ActionCell = styled.td`
  text-align: center;
  padding: 10px 0 !important;
`

export const ProductDesc = styled.div`
  color:#000;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 28px;
  @media(min-width:1200px){        
    font-size: 16px;
    line-height: 28px;
  }
  p {
    font-size: 14px;
    line-height: 30px;
    @media(min-width:1200px){        
      font-size: 16px;
      line-height: 32px;
    }
  }
`

const displayFields = [
  "Product",
  "Image",
  "Price",
  "SKU",
  "Description",
  "Location",
  "Gauge",
  "Manufacturer",
  "Roof Style",
  "Condition",
  "Building Tags",
  "Actions"
];

const ProductCompare = () => {
  const { comparisonData, setComparisonData } = useContext(Context);

  const removeItem = (id) => {
    const newData = comparisonData.filter(item => item.id !== id);
    setComparisonData(newData);
  }

  const renderCell = (field, product) => {
    switch (field) {
      case "Product":
        return (<td>{product.title}</td>);
      case "Image":
        return (
          <ImageCell>
            <GatsbyImage
              image={product.image.gatsbyImageData}
              alt={product.title}
            />
          </ImageCell>
        )
      case "Price":
        return (
          <td>
            <strong>
              ${product.price.toLocaleString("en-US")}
            </strong>
          </td>
        )
      case "SKU":
        return (<td>
          {product.skuNumber}
        </td>)
      case "Description":
        return (<td>
          <ProductDesc>
            <p>{product.description.childMarkdownRemark.html.replace(/<[^>]+>/g, '')}</p>
          </ProductDesc>
        </td>)
      case "Location":
        return (<td>
          {product.city}, {product.state}{" "}
          {product.zipcode}
        </td>)
      case "Gauge":
        return (<td>
          {product["gaugeTubing"] || 0}-Gauge Tubing,{" "}
          {product["gaugePanels"] || 0}-Gauge Panels (Upgrade Available)
        </td>)
      case "Manufacturer":
        return (<td>
          {product.manufacturer}
        </td>)
      case "Roof Style":
        return (<td>
          {product.roofStyleType}
        </td>)
      case "Condition":
        return (<td>
          {product.buildingCondition}
        </td>)
      case "Building Tags":
        return (<td>
          {(product["buildingTags"] || []).join(", ")}
        </td>)
      case "Actions":
        return (<ActionCell>
          <div onClick={() => removeItem(product.id)}>
            <PrimaryButton text="Remove" size="sm" />
          </div>
        </ActionCell>)
      default:
        return null;
    }
  }

  if (!comparisonData || comparisonData.length === 0) {
    return (
      <Container>
        <SectionPageTitle textAlign="left" className="h4 mb-40">Product Comparison</SectionPageTitle>
        <ComparisonTableData>
          <p>You have not chosen any products to compare.</p>
        </ComparisonTableData>
      </Container>
    )
  }

  return (
    <Container>
      <SectionPageTitle textAlign="left" className="h4 mb-40">Product Comparison</SectionPageTitle>
      <ComparisonTableData>
        <ComparisonTable>
          <tbody>
            <tr>
              <td colSpan={comparisonData.length + 1}>Product Details</td>
            </tr>
            {displayFields.map(field => (
              <tr>
                <td>{field}</td>
                {comparisonData.map(p => renderCell(field, p))}
              </tr>
            ))}
          </tbody>
        </ComparisonTable>
      </ComparisonTableData>
    </Container>
  )
}

const Page = ({ location }) => {

  return (
    <Layout location={location}>
       <Helmet><meta name="robots" content="noindex, nofollow" /></Helmet>
      <Seo title="Compare" description="Product Comparison" />
      <Section ept="100px" epb="0" xpt="100px" xpb="0" pt="64px" pb="0" bgColor="#fff" className="section-compare">
        <ProductCompare />
      </Section>
    </Layout>
  )
}

export default Page;